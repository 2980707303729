<template>
<div>
    <v-btn class="ma-7"
      depressed
      color="primary"
    >
      利益情報追加
    </v-btn>
    <v-btn class="ma-3"
      depressed
      color="error"
    >
      損益情報追加
    </v-btn>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-subheader>金額</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field
          label="金額"
          value=""
          suffix="円"
          v-model="name"
        ></v-text-field>
      </v-col>
    </v-row>

     <v-row>
      <v-col cols="4">
        <v-subheader>用途</v-subheader>
      </v-col>
      <v-col cols="8">

     <v-select
      v-model="select"
      :items="items"
      :error-messages="selectErrors"
      label="用途"
      required
      @change="$v.select.$touch()"
      @blur="$v.select.$touch()"
    ></v-select>
      </v-col>
    </v-row>

    <v-row>
     <v-col cols="4">
        <v-subheader>門徒名</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-text-field
          label="名前"
          value=""
          v-model="name"
        ></v-text-field>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="4">
        <v-subheader>日付</v-subheader>
      </v-col>
      <v-col cols="8">
       
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="日付入力"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    </v-row>  
      <v-textarea class="ma-4" v-model="form"
              color="teal" label="詳細">
      </v-textarea>       


 
  </v-container>
  

    <v-checkbox
      v-model="checkbox"
      :error-messages="checkboxErrors"
      label="最終確認チェック"
      required
      @change="$v.checkbox.$touch()"
      @blur="$v.checkbox.$touch()"
      class="ma-4"
    ></v-checkbox>
 

    <v-btn
      class="ma-7 white--text"
      @click="submit"
      color="green"
    >
      入力完了
    </v-btn>
    <v-btn @click="clear" 
     class="ma-3 white--text"
     color="red"
     
    >
      キャンセル
    </v-btn>
    
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(10) },
      email: { required, email },
      select: { required },
      checkbox: {
        checked (val) {
          return val
        },
      },
    },

    data: () => ({
      name: '',
      email: '',
      select: null,
      items: [
        '会計費',
        'お寺費用',
        '交通費',
        'その他諸々',
      ],
      checkbox: false,
    }),

    computed: {
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('You must agree to continue!')
        return errors
      },
      selectErrors () {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('用途の入力が必要です')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
    
    },

    methods: {
      submit () {
        this.$v.$touch()
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.select = null
        this.checkbox = false
      },
    },
  }
</script>